import React, { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { Link, useParams } from "react-router-dom";
import { useMergeState } from "utils/custom-hooks";
import ProductCard from "components/ProductCard";
import Loader from "components/Loader";
import PublicProfileNav from "./Nav";
import {
  addToMyList,
  follow,
  getPublicProfile,
  listFriends,
  listMyList,
  removeFromMyList,
  unfollow,
} from "api";
import { wrapFullName } from "utils/common";
import Nav from "components/Nav";
import { UserType } from "utils/types";
import Button from "components/Button";
import { debounce } from "lodash";

type Props = {
  isLoggedIn: boolean;
  user: UserType;
  notificationStats: any;
};

// is profile user a follower of logged in user
const isFollower = (
  loggedInUserId: string,
  profileUserId: string,
  followers: Array<any>
) =>
  followers.some(
    (elem) =>
      elem?.follower?._id === profileUserId &&
      elem?.user?._id === loggedInUserId
  );

// is logged in user following profile user
const isFollowing = (
  loggedInUserId: string,
  profileUserId: string,
  following: Array<any>
) =>
  following.find(
    (elem) =>
      elem?.user?._id === profileUserId &&
      elem?.follower?._id === loggedInUserId
  );

export default function PublicProfileContainer({
  user: loggedInUser,
  isLoggedIn,
  notificationStats,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { userName = "" } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState("");
  const [searchLoading, setsearchLoading] = useState(false);

  const [state, setState] = useMergeState({
    isLoading: false,
    user: {},
    feed: [],
    mylist: [],
    // logged in user's followers and following
    followers: [],
    following: [],
  });

  const init = async () => {
    try {
      setState({ isLoading: true });

      const mylistResponse = await listMyList();

      const response = await getPublicProfile({
        userName,
        search: searchQuery,
      });

      let payload: any = {
        user: response?.data?.user,
        feed: response?.data?.feed,
        mylist: mylistResponse?.data?.mylist,
      };

      if (isLoggedIn) {
        const listFriendsResponse = await listFriends();

        payload = {
          ...payload,
          followers: listFriendsResponse?.data?.followers,
          following: listFriendsResponse?.data?.following,
        };

        setState(payload);
      }

      setState(payload);
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
      window.location.href = "/signup";
    } finally {
      setState({ isLoading: false });
    }
  };

  const shouldBlur = (index: number) => !isLoggedIn && index > 3;

  const shouldShowFollow = () => {
    if (
      loggedInUser?._id !== state?.user?._id &&
      !isFollower(loggedInUser?._id, state?.user?._id, state?.followers)
    ) {
      return true;
    }

    return false;
  };

  const shouldShowUnFollow = () => {
    if (isFollowing(loggedInUser?._id, state?.user?._id, state?.following)) {
      return true;
    }

    return false;
  };

  const handleFollow = async () => {
    try {
      const response = await follow({ user: state?.user?._id });

      init();

      enqueueSnackbar(response?.message, { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleUnfollow = async () => {
    try {
      const response = await unfollow({ user: state?.user?._id });

      init();

      enqueueSnackbar(response?.message, { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const loadData = useCallback(
    debounce(async () => {
      try {
        setsearchLoading(true);
        const response = await getPublicProfile({
          userName,
          search: searchQuery,
        });

        let payload: any = {
          user: response?.data?.user,
          feed: response?.data?.feed,
        };

        if (isLoggedIn) {
          const listFriendsResponse = await listFriends();

          payload = {
            ...payload,
            followers: listFriendsResponse?.data?.followers,
            following: listFriendsResponse?.data?.following,
          };

          setState(payload);
        }

        setState(payload);
      } catch (error: any) {
        enqueueSnackbar(error?.message, { variant: "error" });
        window.location.href = "/signup";
      } finally {
        setsearchLoading(false);
      }
    }, 200),
    [searchQuery, enqueueSnackbar]
  ); // Include all used variables in the dependency array

  React.useEffect(() => {
    if (!userName) {
      return;
    }
    init();
  }, []);

  React.useEffect(() => {
    if (searchData === "") {
      init();
    } else {
      loadData();
    }
  }, [loadData]);

  const handleSearchChange = useCallback(
    debounce((value) => {
      setSearchQuery(value);
    }, 300),
    []
  );

  const handleAddToMyList = async (feedId: string) => {
    try {
      const response = await addToMyList({ feed: feedId });
      enqueueSnackbar(response?.message, { variant: "success" });
      await init();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleRemoveFromMyList = async (feedId: string) => {
    try {
      const response = await removeFromMyList({ feed: feedId });

      enqueueSnackbar(response?.message, { variant: "success" });

      await init();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  return (
    <div>
      {state?.isLoading ? (
        <div className="w-full h-screen flex justify-center mt-10">
          <Loader loading={state?.isLoading} />
        </div>
      ) : (
        <div className="w-full min-h-screen sm:h-screen overflow-y-scroll overflow-x-scroll p-4 lg:px-8 pt-8 pb-40">
          {isLoggedIn ? (
            <Nav
              user={loggedInUser}
              isLoggedIn={isLoggedIn}
              notificationStats={notificationStats}
            />
          ) : (
            <PublicProfileNav />
          )}

          <div className="my-8">
            <input
              type="text"
              name="search"
              placeholder="Search"
              autoComplete="off"
              value={searchData}
              onChange={(event) => {
                setSearchData(event.target.value);
                handleSearchChange(event.target.value);
              }}
              className="shadow rounded py-2 px-3 w-2/5 text-gray-700 leading-tight bg-[#F6F6F6] focus:outline-none focus:shadow-outline"
            />
            <div className="flex flex-col lg:flex-row justify-between lg:items-center">
              <div className="text-xl mr-8">
                {wrapFullName(state?.user?.firstName, state?.user?.lastName)}
              </div>

              <div className="flex justify-between items-center mt-4 lg:mt-0">
                {isLoggedIn && (
                  <div className="text-gray-dark text-lg flex items-center mr-8">
                    {shouldShowFollow() && !shouldShowUnFollow() && (
                      <Button
                        className="h-8 text-sm font-semibold px-2 lg:px-6"
                        onClick={handleFollow}
                      >
                        FOLLOW
                      </Button>
                    )}

                    {shouldShowUnFollow() && (
                      <button
                        className="h-8 text-sm font-semibold px-2 lg:px-6 bg-[#D0D3D9E5] text-[#50555CBF] border-[1px] border-[#19402714] rounded-md"
                        onClick={handleUnfollow}
                      >
                        UNFOLLOW
                      </button>
                    )}
                  </div>
                )}

                <div className="mr-8">
                  <Link
                    to={`/friends?user=${state?.user?.userName}`}
                    className="hover:underline"
                  >
                    <span className="font-bold mr-2">
                      {state?.user?.followers}
                    </span>
                    <span>
                      {state?.user?.followers > 1 ? "Followers" : "Follower"}
                    </span>
                  </Link>
                </div>

                <div>
                  <Link
                    to={`/friends?user=${state?.user?.userName}`}
                    className="hover:underline"
                  >
                    <span className="font-bold mr-2">
                      {state?.user?.following}
                    </span>
                    <span>Following</span>
                  </Link>
                </div>
              </div>
            </div>
            {searchLoading ? (
              <div className="w-full h-screen flex justify-center mt-10">
                <Loader loading={searchLoading} />
              </div>
            ) : (
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-8">
                {state?.feed?.map((feed: any, index: number) => (
                  <ProductCard
                    key={feed?._id}
                    feed={feed}
                    mylist={state?.mylist}
                    isPublicProfile
                    shouldBlur={shouldBlur(index)}
                    onAddToMyList={handleAddToMyList}
                    onRemoveFromMyList={handleRemoveFromMyList}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
