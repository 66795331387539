/* eslint-disable import/no-unresolved */
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "components/TextField";
import Button from "components/Button";
import { useMergeState } from "utils/custom-hooks";
import { GenderOptions, PreferencesOptions } from "utils/constants";
import { updateProfile } from "api";
import Images from "assets/images";
import ErrorMessage from "components/ErrorMessage";
import dayjs from "dayjs";

type Props = {
  setUser: (user: any) => void;
};

export default function OnboardingContainer({ setUser }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [state, setState] = useMergeState({
    isLoading: false,

    birthday: null,
    gender: "",
    zip: "",
    phone: "",
    preferences: [],
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: "",
      },
    });
  };

  const handleBirthdayChange = (newValue: any) => {
    setState({ birthday: newValue });
  };

  const isPreferenceSelected = (preference: any) =>
    state?.preferences.includes(preference?.value);

  const handleSelectPreference = (preference: any) => {
    const updatedPreferences = [...state.preferences];

    const index = updatedPreferences.findIndex(
      (elem) => elem === preference.value
    );

    if (index === -1) {
      updatedPreferences.push(preference.value);
    } else {
      updatedPreferences.splice(index, 1);
    }

    setState({ preferences: updatedPreferences });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state?.birthday) {
      payload = { birthday: "Birthday is required", ...payload };
      isValid = false;
    }

    if (!state?.gender) {
      payload = { gender: "Gender is required", ...payload };
      isValid = false;
    }

    if (!state?.zip) {
      payload = { zip: "Zip is required", ...payload };
      isValid = false;
    }

    if (!state?.phone) {
      payload = { phone: "Phone is required", ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleSubmit = async () => {
    try {
      if (!isFormValid()) {
        return;
      }

      setState({ isLoading: true });

      const response = await updateProfile({
        birthday: state?.birthday,
        gender: state?.gender,
        zip: state?.zip,
        phone: state?.phone,
        preferences: state?.preferences,
      });

      setUser(response?.data?.user);

      navigate("/friends");
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen h-full">
      <Link to="/home">
        <img src={Images.Logo} className="absolute top-8 left-8 h-12 lg:h-20" />
      </Link>

      <div className="px-4 py-32 justify-center flex flex-col items-center">
        <div className="text-green-dark text-2xl lg:text-4xl text-center mt-4 px-64 ">
          We’re so excited you’re here! Couple quick q’s for you so we can know
          you a little better:
        </div>

        {/* <div className="text-green-dark text-2xl lg:text-4xl text-center mt-4">
          Tell us a little more about you so we can get to know you :)
        </div> */}

        <div className="flex justify-center mt-8">
          <div className="w-2/5 lg:w-full">
            <div className="w-full flex gap-5 items-center">
              <div className="text-n-2 font-medium text-lg mb-1">Birthday</div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={
                    state?.birthday ? dayjs(state?.birthday) : state?.birthday
                  }
                  onChange={handleBirthdayChange}
                  sx={{
                    ".MuiInputBase-root": {
                      height: 64,
                    },
                  }}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </LocalizationProvider>

              {state?.errors?.birthday && (
                <ErrorMessage message={state?.errors?.birthday} />
              )}
            </div>

            <div className="mt-8 flex gap-7 items-center">
              <div className="text-n-2 font-medium text-lg mb-1">Gender</div>
              <FormControl variant="outlined" className="input-select-field">
                <Select
                  fullWidth
                  displayEmpty
                  variant="outlined"
                  name="gender"
                  value={state?.gender}
                  onChange={handleChange}
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {GenderOptions.map((item: any) => (
                    <MenuItem key={item.label} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {state?.errors?.gender && (
                <ErrorMessage message={state?.errors?.gender} />
              )}
            </div>

            <div className="mt-8 flex gap-16 items-center">
              <div className="text-n-2 font-medium text-lg mb-1">Zip</div>
              <TextField
                type="number"
                name="zip"
                placeholder="94105"
                value={state?.zip}
                onChange={handleChange}
                error={state?.errors?.zip}
                onWheel={(event: any) => event.target.blur()}
                onInput={(event: any) => {
                  if (
                    // @ts-ignore
                    event?.target?.value
                  ) {
                    // @ts-ignore
                    event.target.value = Math.max(
                      0,
                      // @ts-ignore
                      parseInt(event.target.value, 10)
                    )
                      .toString()
                      .slice(0, 5);
                  }
                }}
              />
            </div>

            <div className="mt-8 flex gap-10 items-center">
              <div className="text-n-2 font-medium text-lg mb-1">Phone</div>
              <TextField
                type="number"
                name="phone"
                value={state?.phone}
                onChange={handleChange}
                placeholder="9909909900"
                error={state?.errors?.phone}
                onWheel={(event: any) => event.target.blur()}
                onInput={(event: any) => {
                  if (
                    // @ts-ignore
                    event?.target?.value
                  ) {
                    // @ts-ignore
                    event.target.value = Math.max(
                      0,
                      // @ts-ignore
                      parseInt(event.target.value, 10)
                    )
                      .toString()
                      .slice(0, 10);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-full justify-center flex  px-36 gap-10">
          <div className="text-n-2 font-medium text-lg justify-center py-10 ">
            Interests:
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-5 gap-8 mt-4 w-full">
            {PreferencesOptions.map((preference) => (
              <div
                key={preference?.value}
                className={`flex justify-center items-center h-20 shadow-lg rounded-md relative cursor-pointer ${
                  isPreferenceSelected(preference) ? "bg-n-1" : ""
                }`}
                onClick={() => handleSelectPreference(preference)}
              >
                <div className="text-lg font-medium">{preference?.label}</div>

                {isPreferenceSelected(preference) && (
                  <div>
                    <img
                      className="absolute top-5 right-[-15px]"
                      src={Images.CheckMark}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <Button
          className="text-lg lg:text-2xl w-1/2 font-semibold px-6 h-12 lg:h-16 mt-12 "
          onClick={handleSubmit}
        >
          DONE
        </Button>
      </div>
    </div>
  );
}
