import UserLink from "components/UserLink";
import { Link } from "react-router-dom";
import { UserType } from "utils/types";

type Props = {
  user: UserType;
};

const Actions = [
  {
    label: "My profile",
    path: "/profile",
  },
  {
    label: "Friends",
    path: "/friends",
  },
  {
    label: "Account privacy",
    path: "/account-privacy",
  },
  {
    label: "Delete my account",
    path: "/delete-account",
  },
  {
    label: "Contact us",
    path: "https://www.4oneone.co/contact-us",
  },
  {
    label: "FAQ",
    path: "https://www.4oneone.co/",
  },
  {
    label: "Privacy Policy",
    path: "https://www.4oneone.co/privacy-policy",
  },
  {
    label: "Terms & Conditions",
    path: "https://www.4oneone.co/terms-of-service",
  },
];

export default function SettingsContainer({ user }: Props) {
  return (
    <div>
      <UserLink user={user} />

      <div className="font-bold text-xl mt-8">Settings & Privacy</div>

      <div className="mt-8 text-lg">
        {Actions.map((action) => (
          <div key={action?.label} className="my-1">
            <Link to={action?.path} className="hover:underline">
              {action?.label}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
