import React from "react";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "components/TextField";
import Button from "components/Button";
import Loader from "components/Loader";
import { useMergeState } from "utils/custom-hooks";
import { GenderOptions, PreferencesOptions } from "utils/constants";
import { updateProfile } from "api";
import Images from "assets/images";
import { UserType } from "utils/types";
import ErrorMessage from "components/ErrorMessage";

type Props = {
  user: UserType;
  setUser: (user: UserType) => void;
};

export default function ProfileContainer({ user, setUser }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useMergeState({
    isLoading: false,

    birthday: null,
    gender: "",
    zip: "",
    phone: "",
    preferences: [],
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: "",
      },
    });
  };

  const handleBirthdayChange = (newValue: any) => {
    setState({ birthday: newValue });
  };

  const isPreferenceSelected = (preference: any) =>
    state?.preferences.includes(preference?.value);

  const handleSelectPreference = (preference: any) => {
    const updatedPreferences = [...state.preferences];

    const index = updatedPreferences.findIndex(
      (elem) => elem === preference.value
    );

    if (index === -1) {
      updatedPreferences.push(preference.value);
    } else {
      updatedPreferences.splice(index, 1);
    }

    setState({ preferences: updatedPreferences });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state?.birthday) {
      payload = { birthday: "Birthday is required", ...payload };
      isValid = false;
    }

    if (!state.gender) {
      payload = { gender: "Gender is required", ...payload };
      isValid = false;
    }

    if (!state.zip) {
      payload = { zip: "Zip is required", ...payload };
      isValid = false;
    }

    if (!state.phone) {
      payload = { phone: "Phone is required", ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleSubmit = async () => {
    try {
      if (!isFormValid()) {
        return;
      }

      setState({ isLoading: true });

      const response = await updateProfile({
        birthday: state?.birthday,
        gender: state?.gender,
        zip: state?.zip,
        phone: state?.phone,
        preferences: state?.preferences,
      });

      setUser(response?.data?.user);

      enqueueSnackbar(response?.message, { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  React.useEffect(() => {
    if (user?._id) {
      setState({
        birthday: user?.birthday,
        gender: user?.gender,
        zip: user?.zip,
        phone: user?.phone,
        preferences: user?.preferences,
      });
    }
  }, []);

  return (
    <div>
      {state?.isLoading ? (
        <div className="w-full h-screen flex justify-center mt-10">
          <Loader loading={state?.isLoading} />
        </div>
      ) : (
        <div>
          <div className="flex justify-center mt-8">
            <div className="w-11/12 lg:w-2/5">
              <div className="w-full">
                <div className="text-n-2 font-medium text-lg mb-1">
                  Birthday
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={
                      state?.birthday ? dayjs(state?.birthday) : state?.birthday
                    }
                    onChange={handleBirthdayChange}
                    sx={{
                      ".MuiInputBase-root": {
                        height: 64,
                      },
                    }}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </LocalizationProvider>

                {state?.errors?.birthday && (
                  <ErrorMessage message={state?.errors?.birthday} />
                )}
              </div>

              <div className="mt-8">
                <div className="text-n-2 font-medium text-lg mb-1">Gender</div>
                <FormControl variant="outlined" className="input-select-field">
                  <Select
                    fullWidth
                    displayEmpty
                    variant="outlined"
                    name="gender"
                    value={state?.gender}
                    onChange={handleChange}
                  >
                    <MenuItem disabled value="">
                      <em>Select</em>
                    </MenuItem>
                    {GenderOptions.map((item: any) => (
                      <MenuItem key={item.label} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {state?.errors?.gender && (
                  <ErrorMessage message={state?.errors?.gender} />
                )}
              </div>

              <div className="mt-4">
                <div className="text-n-2 font-medium text-lg mb-1">Zip</div>
                <TextField
                  type="number"
                  name="zip"
                  placeholder="94105"
                  value={state?.zip}
                  onChange={handleChange}
                  error={state?.errors?.zip}
                  onWheel={(event: any) => event.target.blur()}
                  onInput={(event: any) => {
                    if (
                      // @ts-ignore
                      event?.target?.value
                    ) {
                      // @ts-ignore
                      event.target.value = Math.max(
                        0,
                        // @ts-ignore
                        parseInt(event.target.value, 10)
                      )
                        .toString()
                        .slice(0, 5);
                    }
                  }}
                />
              </div>

              <div className="mt-4">
                <div className="text-n-2 font-medium text-lg mb-1">Phone</div>
                <TextField
                  type="number"
                  name="phone"
                  value={state?.phone}
                  onChange={handleChange}
                  placeholder="9909909900"
                  error={state?.errors?.phone}
                  onWheel={(event: any) => event.target.blur()}
                  onInput={(event: any) => {
                    if (
                      // @ts-ignore
                      event?.target?.value
                    ) {
                      // @ts-ignore
                      event.target.value = Math.max(
                        0,
                        // @ts-ignore
                        parseInt(event.target.value, 10)
                      )
                        .toString()
                        .slice(0, 10);
                    }
                  }}
                />
              </div>

              <div className="mt-4">
                <div className="text-n-2 font-medium text-lg mb-1">Loves</div>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 mt-4">
                  {PreferencesOptions.map((preference) => (
                    <div
                      key={preference?.value}
                      className={`flex justify-center items-center h-20 shadow-lg rounded-md relative cursor-pointer ${
                        isPreferenceSelected(preference) ? "bg-n-1" : ""
                      }`}
                      onClick={() => handleSelectPreference(preference)}
                    >
                      <div className="text-lg font-medium">
                        {preference?.label}
                      </div>

                      {isPreferenceSelected(preference) && (
                        <div>
                          <img
                            className="absolute top-5 right-[-15px]"
                            src={Images.CheckMark}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <Button
                className="text-lg lg:text-2xl font-semibold px-6 w-full h-12 lg:h-16 mt-12"
                onClick={handleSubmit}
              >
                UPDATE PROFILE
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
