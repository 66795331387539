import { Link } from "react-router-dom";
import Images from "assets/images";

export default function PublicProfileNav() {
  return (
    <div className="w-full">
      <div className="flex justify-between items-center">
        <div>
          <img src={Images.Logo} className="h-12 lg:h-20" />
        </div>

        <div className="text-dark text-xl font-medium">
          <Link to="/signup" className="hover:underline">
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
}
