import axios from "axios";
import { FOUR_ONE_ONE_TOKEN } from "utils/constants";

const getHeaders = () => ({
  "x-access-token": localStorage.getItem(FOUR_ONE_ONE_TOKEN) || "",
});

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
});

const Exception = (message: any) => {
  const error: any = new Error(message);

  error.success = false;

  return error;
};

const processError = (error: any) => {
  if (error?.response?.status === 401) {
    localStorage.removeItem(FOUR_ONE_ONE_TOKEN);
    window.location.href = "/";
  }

  if (error?.response?.data) {
    // client received an error response (5xx, 4xx)
    throw Exception(error.response.data?.message);
  }

  if (error?.request) {
    // client never received a response, or request never left
    throw Exception("It's not you, it's us, want to give it another try?");
  }

  // anything else
  throw Exception("Oops! Something went wrong.");
};

// ---------- USERS ----------

export const signup = async (payload: {
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}) => {
  try {
    const response = await API.post("/users/signup", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const signin = async (payload: {
  emailOrUsername: string;
  password: string;
}) => {
  try {
    const response = await API.post("/users/signin", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await API.get("/users/me", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getPublicProfile = async (params: {
  userName: string;
  search: string;
}) => {
  try {
    const response = await API.get("/users/profile", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getPublicFriends = async (params: { userName: string }) => {
  try {
    const response = await API.get("/users/friends", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateProfile = async (payload: {
  firstName?: string;
  lastName?: string;
  birthday?: string;
  gender?: string;
  zip?: string;
  phone?: string;
  preferences?: Array<string>;
}) => {
  try {
    const response = await API.put("/users/profile", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const inviteUser = async (payload: { email: string }) => {
  try {
    const response = await API.post("/users/invite", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateAccountPrivacy = async (payload: { private: boolean }) => {
  try {
    const response = await API.put("/users/account-privacy", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- FEED ----------

export const listFeed = async (params: {
  type: string;
  pageIndex: Number;
  search: string;
}) => {
  try {
    const response = await API.get("/feed", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const deleteFeed = async (params: { id: string }) => {
  try {
    const response = await API.delete("/feed", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MY LIST ----------

export const listMyList = async () => {
  try {
    const response = await API.get("/my-list", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const addToMyList = async (payload: { feed: string }) => {
  try {
    const response = await API.post("/my-list/add", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const removeFromMyList = async (payload: { feed: string }) => {
  try {
    const response = await API.post("/my-list/remove", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- PRODUCT ----------

export const getProduct = async (params: { id: string }) => {
  try {
    const response = await API.get("products", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateRating = async (payload: {
  id: string;
  rating: number | null;
}) => {
  try {
    const response = await API.put("products/rating", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateReview = async (payload: { id: string; review: string }) => {
  try {
    const response = await API.put("products/review", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const postComment = async (payload: { id: string; comment: string }) => {
  try {
    const response = await API.post("products/comments", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- FRIENDS ----------

export const listFriends = async (params?: { userName: string }) => {
  try {
    const response = await API.get("/friends", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const listSuggestedFriends = async () => {
  try {
    const response = await API.get("/friends/suggested", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const listFollowRequests = async () => {
  try {
    const response = await API.get("/friends/follow-requests", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const searchFriends = async (params: { q: string }) => {
  try {
    const response = await API.get("/friends/search", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const follow = async (payload: { user: string }) => {
  try {
    const response = await API.post("/friends/follow", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const decideFollowRequest = async (payload: {
  id: string;
  decision: string;
}) => {
  try {
    const response = await API.post(
      "/friends/follow-requests/decide",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const unfollow = async (payload: { user: string }) => {
  try {
    const response = await API.post("/friends/unfollow", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const removeFollower = async (payload: { user: string }) => {
  try {
    const response = await API.post("/friends/remove-follower", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- NOTIFICATIONS ----------

export const listNotifications = async () => {
  try {
    const response = await API.get("/notifications", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getNotificationStats = async () => {
  try {
    const response = await API.get("/notifications/stats", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const markNotificationsAsRead = async () => {
  try {
    const response = await API.post(
      "/notifications/mark-as-read",
      {},
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};
