import React from "react";
import { useSnackbar } from "notistack";
import Switch from "@mui/material/Switch";
import Loader from "components/Loader";
import UserLink from "components/UserLink";
import { UserType } from "utils/types";
import { useMergeState } from "utils/custom-hooks";
import { updateAccountPrivacy } from "api";

type Props = {
  user: UserType;
  setUser: (user: UserType) => void;
};

export default function AccountPrivacyContainer({ user, setUser }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useMergeState({
    isLoading: false,
    isPrivate: false,
  });

  const handleChangePrivate = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      setState({ isLoading: true, isPrivate: event.target.checked });

      const response = await updateAccountPrivacy({
        private: event.target.checked,
      });

      setUser(response?.data?.user);
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  React.useEffect(() => {
    if (user?._id) {
      setState({ isPrivate: user?.private });
    }
  }, []);

  return (
    <div>
      <div>
        {state?.isLoading ? (
          <div className="w-full h-screen flex justify-center mt-10">
            <Loader loading={state?.isLoading} />
          </div>
        ) : (
          <div>
            <UserLink user={user} />

            <div className="flex items-center mt-8">
              <div className="font-bold text-xl mr-20">Private account</div>

              <Switch
                checked={state?.isPrivate}
                onChange={handleChangePrivate}
                inputProps={{ "aria-label": "controlled" }}
                color="info"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
