/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useMergeState } from "utils/custom-hooks";
import ProductCard from "components/ProductCard";
import Loader from "components/Loader";
import { FeedType } from "utils/constants";
import { addToMyList, listFeed, listMyList, removeFromMyList } from "api";
import { debounce } from "lodash";

export default function HomeContainer() {
  const { enqueueSnackbar } = useSnackbar();
  const loaderRef = useRef<HTMLDivElement>(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [state, setState] = useMergeState({
    isLoading: false,
    mylist: [],
    feed: [],
    shouldShowShareProfileDialog: false,
  });
  const [hasMore, setHasMore] = useState(false); // State to track if more items are available
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState("");

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // If the observed element is in view and we are not currently loading
        if (entries[0].isIntersecting && hasMore && !state?.isLoading) {
          setPageIndex((prevPageIndex) => prevPageIndex + 1);
        }
      },
      {
        // root: null, // using the viewport
        rootMargin: "5px",
        threshold: 0.1, // trigger the callback when 10% of the element is visible
      }
    );

    // Attach the observer to the loader div
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (loaderRef.current) {
        observer.disconnect();
      }
    };
  }, [hasMore, state.isLoading]);

  const init = async () => {
    try {
      setState({ isLoading: true });

      const mylistResponse = await listMyList();
      const response = await listFeed({
        type: FeedType.Friends,
        pageIndex: 0,
        search: searchQuery,
      });

      setState({
        mylist: mylistResponse?.data?.mylist,
        feed: response?.data?.feed,
      });
      if (response?.data?.feed.length !== 0) {
        setHasMore(true);
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  const loadData = useCallback(
    debounce(async () => {
      try {
        const response = await listFeed({
          type: searchQuery !== "" ? "search" : FeedType.Friends,
          pageIndex,
          search: searchQuery,
        });
        setState({
          feed:
            pageIndex === 0
              ? response.data.feed
              : [...state.feed, ...response.data.feed],
          isLoading: false,
        });
        setHasMore(response.data.feed.length > 0);
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    }, 200),
    [pageIndex, searchQuery, enqueueSnackbar]
  ); // Include all used variables in the dependency array

  const handleAddToMyList = async (feedId: string) => {
    try {
      const response = await addToMyList({ feed: feedId });
      enqueueSnackbar(response?.message, { variant: "success" });
      await init();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleRemoveFromMyList = async (feedId: string) => {
    try {
      const response = await removeFromMyList({ feed: feedId });

      enqueueSnackbar(response?.message, { variant: "success" });

      await init();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  // fetching data
  useEffect(() => {
    if (pageIndex === 0 && searchData === "") {
      init();
    } else {
      loadData();
    }
  }, [loadData]);

  const handleSearchChange = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setPageIndex(0); // Reset pageIndex to fetch from the beginning
      setHasMore(true);
      setState({ isLoading: true });
    }, 300),
    []
  );

  return (
    <div>
      <input
        type="text"
        name="search"
        placeholder="Search"
        autoComplete="off"
        value={searchData}
        onChange={(event) => {
          setSearchData(event.target.value);
          handleSearchChange(event.target.value);
        }}
        className="shadow rounded py-2 px-3 w-2/5 text-gray-700 leading-tight bg-[#F6F6F6] focus:outline-none focus:shadow-outline"
      />
      {state?.isLoading ? (
        <div className="w-full h-screen flex justify-center mt-10">
          <Loader loading={state?.isLoading} />
        </div>
      ) : (
        <div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 mt-8">
            {state?.feed?.map((feed: any) => (
              <ProductCard
                key={feed._id}
                feed={feed}
                onAddToMyList={handleAddToMyList}
                mylist={state?.mylist}
                onRemoveFromMyList={handleRemoveFromMyList}
              />
            ))}
          </div>
        </div>
      )}
      <div className="text-center" ref={loaderRef}>
        {hasMore ? (
          <Loader loading={hasMore || state.isLoading} />
        ) : (
          "You've seen it all! Check back soon for new items"
        )}
      </div>

      {/* {state?.shouldShowShareProfileDialog && (
        <ShareProfileDialog
          open={state?.shouldShowShareProfileDialog}
          onSubmit={handleShareProfile}
          onClose={handleCloseShareProfileDialog}
        />
      )} */}
    </div>
  );
}
