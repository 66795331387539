import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSnackbar } from "notistack";

// layouts
import DefaultLayout from "layouts/default";

// containers

// auth
import SignInContainer from "containers/auth/signin";
import SignUpContainer from "containers/auth/signup";

// onboarding
import OnboardingContainer from "containers/onboarding";

// home
import HomeContainer from "containers/home";

// feed
import FeedContainer from "containers/feed";

// friends
import FriendsContainer from "containers/friends";

// my-list
import MyListContainer from "containers/my-list";

// product
import ProductContainer from "containers/product";

// notifications
import NotificationsContainer from "containers/notifications";

// follow-requests
import FollowRequestsContainer from "containers/follow-requests";

// settings
import SettingsContainer from "containers/settings";

// account-privacy
import AccountPrivacyContainer from "containers/account-privacy";

// delete-account
import DeleteAccountContainer from "containers/delete-account";

// logout
import LogoutContainer from "containers/logout";

// public-profile
import PublicProfileContainer from "containers/public-profile";

// components
import Loader from "components/Loader";
import PageNotFound from "components/PageNotFound";
import ProtectedRoute from "components/ProtectedRoute";

// utils
import { useMergeState } from "utils/custom-hooks";

// api
import { getCurrentUser, getNotificationStats } from "api";
import { FOUR_ONE_ONE_TOKEN } from "utils/constants";
import ProfileContainer from "containers/profile";

export default function RoutesContainer() {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useMergeState({
    isLoggedIn: false,
    user: {},
    notificationStats: {
      totalCount: 0,
      unreadCount: 0,
      readCount: 0,
    },
  });

  const isAppLoading =
    localStorage.getItem(FOUR_ONE_ONE_TOKEN) && !state?.isLoggedIn;

  const setUser = (user: any) => {
    setState({ isLoggedIn: true, user });
  };

  const onLogout = () => {
    localStorage.removeItem(FOUR_ONE_ONE_TOKEN);
    window.location.href = "/";
  };

  const init = async () => {
    try {
      if (!localStorage.getItem(FOUR_ONE_ONE_TOKEN)) {
        return;
      }

      const userResponse = await getCurrentUser();

      if (userResponse?.success) {
        setUser(userResponse?.data?.user);
      }

      const notificationStatsResponse = await getNotificationStats();

      setState({
        notificationStats: notificationStatsResponse?.data,
      });
    } catch (error: any) {
      localStorage.removeItem(FOUR_ONE_ONE_TOKEN);

      window.location.href = "/";

      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      {isAppLoading ? (
        <div className="w-full h-screen flex justify-center mt-10">
          <Loader loading={isAppLoading} />
        </div>
      ) : (
        <Routes>
          <Route
            path="signin"
            element={<SignInContainer setUser={setUser} />}
          />

          <Route
            path="signup"
            element={<SignUpContainer setUser={setUser} />}
          />

          <Route
            path="onboarding"
            element={<OnboardingContainer setUser={setUser} />}
          />

          <Route
            path="logout"
            element={<LogoutContainer onLogout={onLogout} />}
          />

          <Route
            path=":userName"
            element={
              <PublicProfileContainer
                user={state?.user}
                isLoggedIn={state?.isLoggedIn}
                notificationStats={state?.notificationStats}
              />
            }
          />

          <Route
            path="/"
            element={
              <DefaultLayout
                isLoggedIn={state?.isLoggedIn}
                user={state?.user}
                notificationStats={state?.notificationStats}
              />
            }
          >
            <Route element={<ProtectedRoute isLoggedIn={state?.isLoggedIn} />}>
              <Route path="home" element={<HomeContainer />} />

              <Route
                path="profile"
                element={
                  <ProfileContainer user={state?.user} setUser={setUser} />
                }
              />

              <Route
                path="feed"
                element={<FeedContainer user={state?.user} />}
              />

              <Route
                path="product"
                element={<ProductContainer user={state?.user} />}
              />

              <Route
                path="friends"
                element={<FriendsContainer user={state?.user} />}
              />

              <Route
                path="my-list"
                element={<MyListContainer user={state?.user} />}
              />

              <Route
                path="notifications"
                element={<NotificationsContainer user={state?.user} />}
              />

              <Route
                path="follow-requests"
                element={<FollowRequestsContainer user={state?.user} />}
              />

              <Route
                path="settings"
                element={<SettingsContainer user={state?.user} />}
              />

              <Route
                path="account-privacy"
                element={
                  <AccountPrivacyContainer
                    user={state?.user}
                    setUser={setUser}
                  />
                }
              />

              <Route
                path="delete-account"
                element={<DeleteAccountContainer user={state?.user} />}
              />
            </Route>

            <Route
              path="/"
              element={
                <Navigate to={state?.isLoggedIn ? "/home" : "/signin"} />
              }
            />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
    </div>
  );
}
