/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-duplicates */
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import Link from "@mui/material/Link";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import TextField from "components/TextField";
import CustomRating from "components/Rating";
import Loader from "components/Loader";
import { useMergeState } from "utils/custom-hooks";
import {
  addToMyList,
  getProduct,
  listMyList,
  postComment,
  removeFromMyList,
  updateRating,
  updateReview,
} from "api";
import { renderPrice, wrapFullName } from "utils/common";
import { UserType } from "utils/types";
import debounce from "lodash.debounce";
import { Link as RouterLink } from "react-router-dom";

type Props = {
  user: UserType;
};

const MyListButton = ({
  onClick,
  type,
}: {
  onClick: () => void;
  type: "add" | "remove";
}) => (
  <button
    className="font-medium px-3 h-8 rounded-md bg-[#BCB2DB] text-white text-sm bottom-0.5 absolute"
    onClick={onClick}
  >
    {type === "add" ? "Save" : "Unsave"}
  </button>
);

export default function ProductContainer({ user }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();
  const productId = searchParams.get("id") || "";
  const feedId = searchParams.get("feed") || "";

  const [state, setState] = useMergeState({
    isLoading: false,
    product: {},
    owner: {},
    comments: [],
    comment: "",
    rating: 0,
    review: "",
    errors: {},
    mylist: [],
  });

  const fullName = wrapFullName(state.owner.firstName, state.owner.lastName);

  const isAlreadyInMyList = (currentFeedId: string, mylist: Array<any>) =>
    mylist.find((elem: any) => elem?.feed?._id === currentFeedId);

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: "",
      },
    });
  };

  const init = async () => {
    try {
      setState({ isLoading: true });

      const productResponse = await getProduct({ id: productId });
      const mylistResponse = await listMyList();

      setState({
        product: productResponse?.data?.product,
        owner: productResponse?.data?.user,
        comments: productResponse?.data?.comments,
        rating: productResponse?.data?.product?.rating,
        review: productResponse?.data?.product?.review,
        mylist: mylistResponse?.data?.mylist,
      });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  const handleAddToMyList = async (feedId: string) => {
    try {
      const response = await addToMyList({ feed: feedId });
      enqueueSnackbar(response?.message, { variant: "success" });
      await init();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleRemoveFromMyList = async (feedId: string) => {
    try {
      const response = await removeFromMyList({ feed: feedId });

      enqueueSnackbar(response?.message, { variant: "success" });

      await init();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleSubmitComment = async () => {
    try {
      if (!state?.comment) {
        setState({ errors: { comment: "Please add a comment" } });
        return;
      }

      const response = await postComment({
        id: state?.product?._id,
        comment: state?.comment,
      });

      enqueueSnackbar(response?.message, { variant: "success" });

      setState({ comment: "" });

      await init();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const allowReview = user?._id === state?.owner?._id;

  const shouldShowRating = () => {
    if (allowReview) {
      return true;
    }

    if (!allowReview && state?.rating) {
      return true;
    }

    return false;
  };

  const shouldShowReview = () => {
    if (allowReview) {
      return true;
    }

    if (!allowReview && state?.review) {
      return true;
    }

    return false;
  };

  const debouncedSaveRating = React.useCallback(
    debounce(async (rating: number | null) => {
      try {
        await updateRating({
          id: productId,
          rating,
        });
      } catch (error: any) {
        enqueueSnackbar(error?.message, { variant: "error" });
      }
    }, 1000),
    []
  );

  const debouncedSaveReview = React.useCallback(
    debounce(async (review: string) => {
      try {
        await updateReview({
          id: productId,
          review,
        });
      } catch (error: any) {
        enqueueSnackbar(error?.message, { variant: "error" });
      }
    }, 1000),
    []
  );

  const handleChangeRating = (newValue: number | null) => {
    setState({ rating: newValue });
    debouncedSaveRating(newValue);
  };

  const handleChangeReview = (event: any) => {
    setState({ review: event?.target?.value });
    debouncedSaveReview(event?.target?.value);
  };

  React.useEffect(() => {
    if (!productId) {
      return;
    }

    init();
  }, []);
  return (
    <div>
      {state?.isLoading ? (
        <div className="w-full h-screen flex justify-center mt-10">
          <Loader loading={state?.isLoading} />
        </div>
      ) : (
        <div className="flex justify-center">
          <div className="w-11/12 lg:w-3/5">
            <div className="bg-[#1940270D] p-4 lg:p-4">
              <div className="grid lg:grid-cols-2 gap-8">
                <div>
                  <RouterLink to={`/${state?.owner.userName}`}>
                    <div className="font-semibold text-lg hover:underline text-center">
                      {fullName}
                    </div>
                  </RouterLink>
                  <div className="shadow-inner rounded-md bg-white p-3 flex flex-col justify-center items-center border">
                    <div className="mt-1 text-center">
                      {state?.product?.companyName}
                    </div>

                    <div className="mt-1 text-center">
                      {state?.product?.productName}
                    </div>

                    <div className="mt-1 text-center">
                      {renderPrice(state?.product?.price)}
                    </div>

                    <div className="my-4">
                      <img
                        src={state?.product?.imgUrl}
                        className="w-[250px] h-[250px] lg:w-[300px] lg:h-[300px] object-cover"
                      />
                    </div>
                    <div className="w-full flex justify-end pt-4 px-4 relative">
                      {isAlreadyInMyList(feedId, state.mylist) ? (
                        <div className="font-medium px-2 w-fit h-8 absolute bottom-0.5 rounded-md bg-green-dark text-white text-sm flex justify-center items-center">
                          <button
                            onClick={() => handleRemoveFromMyList(feedId)}
                          >
                            <CheckOutlinedIcon />
                          </button>
                        </div>
                      ) : (
                        <MyListButton
                          onClick={() => handleAddToMyList(feedId)}
                          type="add"
                        />
                      )}
                    </div>
                  </div>

                  {shouldShowRating() && (
                    <div className="flex justify-center mt-4">
                      <CustomRating
                        name="rating"
                        value={state?.rating}
                        size="large"
                        onChange={(event, newValue) => {
                          handleChangeRating(newValue);
                        }}
                        disabled={!allowReview}
                        icon={<FavoriteOutlinedIcon fontSize="inherit" />}
                        emptyIcon={
                          <FavoriteBorderOutlinedIcon fontSize="inherit" />
                        }
                      />
                    </div>
                  )}

                  {shouldShowReview() && (
                    <div className="mt-4">
                      {allowReview ? (
                        <textarea
                          name="review"
                          rows={2}
                          value={state?.review}
                          onChange={handleChangeReview}
                          placeholder="Leave a quick review"
                          className="border-[1px] border-[#D7DBE1] rounded-md px-4 py-2 w-full placeholder:italic placeholder:text-[#00000040] resize-none"
                        />
                      ) : (
                        <div className="italic text-center">
                          {state?.review}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div>
                  <div className="text-n-2 text-lg">Comments</div>
                  <hr className="w-1/4" />

                  <div className="mt-4">
                    <div>
                      <TextField
                        type="textarea"
                        name="comment"
                        placeholder="Your comment..."
                        value={state?.comment}
                        onChange={handleChange}
                        error={state?.errors?.comment}
                      />

                      <div className="flex justify-end mt-1 text-n-2 font-medium">
                        {/* eslint-disable-next-line */}
                        <Link
                          component="button"
                          variant="body2"
                          onClick={handleSubmitComment}
                          sx={{ color: "#00000080" }}
                        >
                          Submit
                        </Link>
                      </div>
                    </div>

                    <div className="max-h-[350px] overflow-visible overflow-y-scroll pr-4 mt-4">
                      {state?.comments?.map((comment: any) => (
                        <div
                          key={comment?._id}
                          className="border border-slate rounded-md p-2 bg-white my-4"
                        >
                          <div className="text-sm font-medium">
                            {wrapFullName(
                              comment?.user?.firstName,
                              comment?.user?.lastName
                            )}
                          </div>

                          <div className="italic mt-2">{comment?.content}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
