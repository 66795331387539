import React from "react";
import Images from "assets/images";

type Props = {
  onLogout: () => void;
};

export default function LogoutContainer({ onLogout }: Props) {
  React.useEffect(() => {
    setTimeout(() => {
      onLogout();
    }, 1000);
  }, []);

  return (
    <div className="flex justify-center items-center min-h-screen h-full bg-n-1">
      <img src={Images.Logo} className="absolute top-8 left-8 h-12 lg:h-20" />

      <div>
        <div className="text-gray text-5xl text-center italic">
          Logging you out...
        </div>
      </div>
    </div>
  );
}
