import React from "react";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import UserLink from "components/UserLink";
import { useMergeState } from "utils/custom-hooks";
import { UserType } from "utils/types";
import { listNotifications, markNotificationsAsRead } from "api";
import { NotificationEventFeature } from "utils/constants";

type Props = {
  user: UserType;
};

export default function NotificationsContainer({ user }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useMergeState({
    isLoading: false,
    notifications: [],
  });

  const init = async () => {
    try {
      setState({ isLoading: true });

      const response = await listNotifications();

      await markNotificationsAsRead();

      setState({
        notifications: response?.data?.notifications,
      });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  const getNotificationDeepLink = (notification: any) => {
    if (notification.feature === NotificationEventFeature.comments) {
      return `/product?id=${notification?.data?.productId}`;
    }

    if (
      [
        NotificationEventFeature.friends,
        NotificationEventFeature.followRequestAccepted,
      ].includes(notification.feature)
    ) {
      return "/friends";
    }

    if (notification.feature === NotificationEventFeature.followRequest) {
      return "/follow-requests";
    }

    return "/notifications";
  };

  React.useEffect(() => {
    init();
  }, []);
  return (
    <div>
      {state?.isLoading ? (
        <div className="w-full h-screen flex justify-center mt-10">
          <Loader loading={state?.isLoading} />
        </div>
      ) : (
        <div>
          <UserLink user={user} />

          <div className="font-bold text-xl mt-8">Notifications</div>

          <div className="w-full mt-8">
            {state?.notifications?.map((notification: any) => (
              <div key={notification?._id} className="w-fit">
                <Link
                  to={getNotificationDeepLink(notification)}
                  className="hover:underline"
                >
                  <div className="my-4">
                    {[
                      NotificationEventFeature.comments,
                      NotificationEventFeature.friends,
                      NotificationEventFeature.followRequest,
                    ].includes(notification?.feature) && (
                      <span className="font-bold">
                        {notification?.sender?.firstName}
                      </span>
                    )}{" "}
                    {notification?.feature ===
                      NotificationEventFeature.comments && (
                      <span>commented on your post</span>
                    )}
                    {notification?.feature ===
                      NotificationEventFeature.friends && (
                      <span>started following you</span>
                    )}
                    {notification?.feature ===
                      NotificationEventFeature.followRequest && (
                      <span>is requesting to follow you</span>
                    )}
                    {notification?.feature ===
                      NotificationEventFeature.followRequestAccepted && (
                      <div>
                        You are now following{" "}
                        <span className="font-bold">
                          {notification?.sender?.firstName}
                        </span>
                      </div>
                    )}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
