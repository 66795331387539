import React from "react";
import { useSnackbar } from "notistack";
import { Link, useSearchParams } from "react-router-dom";
import Loader from "components/Loader";
import Button from "components/Button";
import UserLink from "components/UserLink";
import {
  listFriends,
  follow,
  unfollow,
  removeFollower,
  searchFriends,
  getPublicFriends,
  listSuggestedFriends,
} from "api";
import { UserType } from "utils/types";
import { wrapFullName } from "utils/common";
import { useMergeState } from "utils/custom-hooks";

type Props = {
  user: UserType;
};

const paginate = (array: Array<any>, page: number, perPage: number = 5) =>
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  array.slice((page - 1) * perPage, page * perPage);

const isFollowingToo = (follower: any, following: Array<any>) => {
  if (following.map((elem) => elem.user._id).includes(follower.follower._id)) {
    return true;
  }

  return false;
};

// is person a follower of logged in user
const isFollower = (personId: string, followers: Array<any>) =>
  followers.some((item: any) => item.follower?._id === personId);

// is logged in user following person
const isFollowing = (personId: string, following: Array<any>) =>
  following?.some((item: any) => item.user?._id === personId);

export default function FriendsContainer({ user }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();

  const forUser = searchParams.get("user") || "";

  const [state, setState] = useMergeState({
    isLoading: false,

    user: {}, // for user

    search: "",
    people: [], // for search
    followers: [],
    following: [],
    totalFollowers: 0,
    totalFollowing: 0,

    isPaginatingSuggestedFriends: false,
    hasNextPaginatedSuggestedFriends: false,
    suggestedFriendsPage: 1,
    paginatedSuggestedFriends: [],
    suggestedFriends: [],
  });

  const handleSearchChange = async (event: any) => {
    setState({
      [event.target.name]: event.target.value,
    });
  };

  const handleSearch = async () => {
    try {
      const response = await searchFriends({ q: state?.search });

      setState({
        people: response?.data,
      });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const init = async () => {
    try {
      setState({ isLoading: true });

      const friendsResponse = await listFriends({ userName: forUser });

      if (forUser) {
        const forUserFriendsResponse = await getPublicFriends({
          userName: forUser,
        });

        setState({
          user: forUserFriendsResponse?.data?.user,
          followers: forUserFriendsResponse?.data?.friends?.followers,
          following: forUserFriendsResponse?.data?.friends?.following,
          totalFollowers: forUserFriendsResponse?.data?.friends?.totalFollowers,
          totalFollowing: forUserFriendsResponse?.data?.friends?.totalFollowing,
        });
      } else {
        const suggestedFriendsResponse = await listSuggestedFriends();

        setState({
          followers: friendsResponse?.data?.followers,
          following: friendsResponse?.data?.following,
          totalFollowers: friendsResponse?.data?.totalFollowers,
          totalFollowing: friendsResponse?.data?.totalFollowing,
          suggestedFriends: suggestedFriendsResponse?.data,
          paginatedSuggestedFriends: paginate(
            suggestedFriendsResponse?.data,
            state.suggestedFriendsPage
          ),
        });
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  const shouldShowUnFollowSearch = (personId: string) => {
    if (isFollowing(personId, state?.following)) {
      return true;
    }

    return false;
  };

  const shouldShowFollowForSearch = (personId: string) => {
    if (
      !isFollower(personId, state?.followers) &&
      !shouldShowUnFollowSearch(personId)
    ) {
      return true;
    }

    if (
      isFollower(personId, state?.followers) &&
      !shouldShowUnFollowSearch(personId)
    ) {
      return true;
    }

    return false;
  };

  const shouldShowRemoveForSearch = (personId: string) => {
    if (
      isFollower(personId, state?.followers) &&
      isFollowing(personId, state?.following)
    ) {
      return true;
    }

    return false;
  };

  const handleFollow = async (user: string) => {
    try {
      const response = await follow({ user });

      setState({
        totalFollowers: response?.data?.totalFollowers,
        totalFollowing: response?.data?.totalFollowing,
      });

      enqueueSnackbar(response?.message, { variant: "success" });

      await init();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleRemoveFollower = async (user: string) => {
    try {
      const response = await removeFollower({ user });

      setState({
        totalFollowers: response?.data?.totalFollowers,
        totalFollowing: response?.data?.totalFollowing,
      });

      enqueueSnackbar(response?.message, { variant: "success" });

      await init();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleUnfollow = async (user: string) => {
    try {
      const response = await unfollow({ user });

      setState({
        totalFollowers: response?.data?.totalFollowers,
        totalFollowing: response?.data?.totalFollowing,
      });

      enqueueSnackbar(response?.message, { variant: "success" });

      await init();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleSuggestedFriendsNextPage = () => {
    setState({ isPaginatingSuggestedFriends: true });

    setTimeout(() => {
      const nextPage = state.suggestedFriendsPage + 1;

      const paginatedSuggestedFriends = paginate(
        state?.suggestedFriends,
        nextPage
      );

      const hasNextPaginatedSuggestedFriends =
        paginatedSuggestedFriends.length < 5;

      setState({
        suggestedFriendsPage: nextPage,
        paginatedSuggestedFriends,
        hasNextPaginatedSuggestedFriends,
        isPaginatingSuggestedFriends: false,
      });
    }, 500);
  };

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (state?.search) {
        await handleSearch();
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [state?.search]);

  return (
    <div>
      {state?.isLoading ? (
        <div className="w-full h-screen flex justify-center mt-10">
          <Loader loading={state?.isLoading} />
        </div>
      ) : (
        <div>
          <div className="flex items-center">
            <div className="mr-12">
              {forUser ? (
                <div className="text-lg">
                  <Link
                    to={`/${state?.user?.userName}`}
                    className="hover:underline"
                  >
                    {wrapFullName(
                      state?.user?.firstName,
                      state?.user?.lastName
                    )}
                  </Link>
                </div>
              ) : (
                <UserLink user={user} />
              )}
            </div>

            {!forUser && (
              <div>
                <input
                  type="text"
                  name="search"
                  placeholder="Search for friends"
                  autoComplete="off"
                  value={state?.search}
                  onChange={handleSearchChange}
                  className="border-[1px] border-slate rounded-md font-medium h-10 px-4"
                />
              </div>
            )}
          </div>

          {state?.search ? (
            <div className="my-12">
              {state?.people?.map((people: any) => (
                <div key={people?._id} className="my-8 w-11/12 lg:w-1/2">
                  <div className="flex justify-between items-center">
                    <div>
                      <Link
                        to={`/${people?.userName}`}
                        className="hover:underline"
                      >
                        {wrapFullName(people?.firstName, people?.lastName)}
                      </Link>
                    </div>

                    <div className="lg:w-2/5 grid grid-cols-2 gap-4">
                      {shouldShowFollowForSearch(people?._id) && (
                        <Button
                          className="font-medium px-3 lg:px-6 w-fit h-10 rounded-md"
                          onClick={() => handleFollow(people?._id)}
                        >
                          Follow
                        </Button>
                      )}

                      {shouldShowUnFollowSearch(people?._id) && (
                        <button
                          className="font-medium px-3 lg:px-6 w-fit h-10 rounded-md bg-[#D0D3D9E5] text-[#50555CBF] border-[1px] border-[#19402714]"
                          onClick={() => handleUnfollow(people?._id)}
                        >
                          Unfollow
                        </button>
                      )}

                      {shouldShowRemoveForSearch(people?._id) && (
                        <button
                          className="font-medium px-3 lg:px-6 w-fit h-10 rounded-md bg-[#D0D3D9E5] text-[#50555CBF] border-[1px] border-[#19402714]"
                          onClick={() => handleRemoveFollower(people?._id)}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {!forUser && (
                <div className="my-8">
                  <div className="italic">Suggested friends</div>

                  {state?.isPaginatingSuggestedFriends ? (
                    <div className="w-full flex justify-center mt-10">
                      <Loader loading={state?.isPaginatingSuggestedFriends} />
                    </div>
                  ) : (
                    <div className="lg:grid lg:grid-cols-4 lg:gap-8 mt-4">
                      {state?.paginatedSuggestedFriends?.map((friend: any) => (
                        <div
                          key={friend?._id}
                          className="flex justify-between items-center h-16 border border-[#19402714] rounded-md relative cursor-pointer p-2 my-4 lg:my-0"
                        >
                          <div>
                            <div>
                              <Link
                                to={`/${friend?.userName}`}
                                className="hover:underline"
                              >
                                {wrapFullName(
                                  friend?.firstName,
                                  friend?.lastName
                                )}
                              </Link>
                            </div>

                            {friend?.followsYou && (
                              <div className="text-xs font-semibold text-[#E1B9FE]">
                                Follows you
                              </div>
                            )}
                          </div>

                          <Button
                            className="font-medium px-6 w-fit h-10 rounded-md"
                            onClick={() => handleFollow(friend?._id)}
                          >
                            Follow
                          </Button>
                        </div>
                      ))}

                      {!state?.hasNextPaginatedSuggestedFriends && (
                        <div
                          className="flex justify-center items-center h-16 border border-[#19402714] rounded-md relative cursor-pointer p-2 my-4 lg:my-0 text-lg font-semibold"
                          onClick={handleSuggestedFriendsNextPage}
                        >
                          View more
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              <div className="lg:grid lg:grid-cols-2 lg:gap-36 my-12">
                <div>
                  <div className="w-fit">
                    <span className="font-bold mr-2">
                      {state?.totalFollowers}
                    </span>
                    <span>
                      {state?.totalFollowers > 1 ? "Followers" : "Follower"}
                    </span>
                    <hr />
                  </div>

                  {state?.followers?.map((follower: any) => (
                    <div
                      key={follower?._id}
                      className="flex justify-between items-center my-8"
                    >
                      <div>
                        <Link
                          to={`/${follower?.follower?.userName}`}
                          className="hover:underline"
                        >
                          {wrapFullName(
                            follower?.follower?.firstName,
                            follower?.follower?.lastName
                          )}
                        </Link>
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        {!isFollowingToo(follower, state?.following) &&
                        !forUser ? (
                          <Button
                            className="font-medium px-6 w-fit h-10 rounded-md"
                            onClick={() =>
                              handleFollow(follower?.follower?._id)
                            }
                          >
                            Follow
                          </Button>
                        ) : (
                          <div />
                        )}

                        {!forUser && (
                          <button
                            className="font-medium px-6 w-fit h-10 rounded-md bg-[#D0D3D9E5] text-[#50555CBF] border-[1px] border-[#19402714]"
                            onClick={() =>
                              handleRemoveFollower(follower?.follower?._id)
                            }
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                <div>
                  <div className="w-fit">
                    <span className="font-bold mr-2">
                      {state?.totalFollowing}
                    </span>
                    <span>Following</span>
                    <hr />
                  </div>

                  {state?.following?.map((following: any) => (
                    <div
                      key={following?._id}
                      className="flex justify-between items-center my-8"
                    >
                      <div>
                        <Link
                          to={`/${following?.user?.userName}`}
                          className="hover:underline"
                        >
                          {wrapFullName(
                            following?.user?.firstName,
                            following?.user?.lastName
                          )}
                        </Link>
                      </div>

                      {!forUser && (
                        <div>
                          <button
                            className="font-medium px-6 w-fit h-10 rounded-md bg-[#D0D3D9E5] text-[#50555CBF] border-[1px] border-[#19402714]"
                            onClick={() => handleUnfollow(following?.user?._id)}
                          >
                            Unfollow
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
