import UserLink from "components/UserLink";
import { UserType } from "utils/types";

type Props = {
  user: UserType;
};

export default function DeleteAccountContainer({ user }: Props) {
  return (
    <div>
      <UserLink user={user} />

      <div className="font-bold text-xl mt-8">You’re leaving?!</div>

      <div className="mt-4 text-lg">
        If that’s how you really feel, just know we’ll miss you, and we’ll have
        you back any time. Send us an email to the address below, and we’ll get
        you squared away
      </div>

      <div className="mt-8 text-lg text-gray-light">
        <a href="mailto:whatsthe4oneone@gmail.com?subject=Delete%20Account">
          <span className="underline">whatsthe4oneone@gmail.com</span>
        </a>
      </div>
    </div>
  );
}
