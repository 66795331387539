import React from "react";

type Props = {
  variant?: string;
  className?: string;
  children: any;
  loading?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export default function Button({
  variant = "primary",
  className,
  children,
  loading,
  disabled,
  onClick,
}: Props) {
  return (
    <button
      className={`hover:bg-[#194027E5] bg-[#194027] text-white rounded-md flex justify-center items-center p-2 ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
