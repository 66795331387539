import ErrorMessage from "components/ErrorMessage";

type Props = {
  type: "text" | "password" | "number" | "textarea";
  label?: string;
  name?: string;
  placeholder?: string;
  defaultValue?: any;
  value: any;
  className?: string;
  rows?: number;
  error?: string;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: any;
  onWheel?: any;
  onInput?: any;
};

export default function TextField(props: Props) {
  const {
    type = "text",
    name,
    placeholder,
    label,
    defaultValue,
    value,
    className = "",
    rows,
    error = "",
    readOnly = false,
    disabled = false,
    onChange,
    onWheel,
    onInput,
  } = props;

  return (
    <div>
      {label && (
        <label
          className={`text-sm font-medium ${error ? "text-rw-red-dark" : ""}`}
        >
          {label}
        </label>
      )}

      {type === "textarea" ? (
        <div className="flex flex-col">
          <textarea
            id={name}
            name={name}
            rows={rows}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            className={`border-[1px] border-slate text-xl font-medium rounded-md px-4 py-2 w-full placeholder:text-xl placeholder:text-slate placeholder:font-medium ${
              label ? "mt-1" : ""
            } ${error ? "border-red-500" : ""} ${className}`}
          />

          {error && <ErrorMessage message={error} />}
        </div>
      ) : (
        <div className="flex flex-col h-20">
          <input
            id={name}
            type={type}
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            readOnly={readOnly}
            disabled={disabled}
            onChange={onChange}
            onWheel={onWheel}
            onInput={onInput}
            className={`border-[1px] border-slate text-xl font-medium h-16 px-4 w-full placeholder:text-xl placeholder:text-slate placeholder:font-medium ${
              label ? "mt-1" : ""
            } ${error ? "border-red-500" : ""} ${className}`}
          />

          {error && <ErrorMessage message={error} />}
        </div>
      )}
    </div>
  );
}
