import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000A1",
    },
    secondary: {
      main: "#D0D3D9",
    },
    info: {
      main: "#E1B9FE",
    },
  },
});

export default theme;
