import Logo from "./411_logo.svg";
import Background from "./background.svg";
import MobileBackground from "./mobile-background.svg";
import CheckMark from "./check_mark.svg";

const Images = {
  Logo,
  Background,
  MobileBackground,
  CheckMark,
};

export default Images;
