import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

interface ConsentDialogProps {
  open: boolean;
  onClose: () => void; // Renamed prop for closing dialog
}

const ConsentDialog: React.FC<ConsentDialogProps> = ({ open, onClose }) => (
  <Dialog
    open={open}
    onClose={onClose} // Use onClose for the Dialog's onClose as well
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      &quot;Use of analytics tracking&quot;
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        We use analytics tracking to improve your browsing experience on our
        website, to show you personalized content, and to analyze our website
        traffic. By browsing our website, you consent to our use of tracking
        technologies.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" autoFocus>
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConsentDialog;
