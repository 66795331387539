import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import TextField from "components/TextField";
import Button from "components/Button";
import { FOUR_ONE_ONE_TOKEN } from "utils/constants";
import { useMergeState } from "utils/custom-hooks";
import Images from "assets/images";
import { signin } from "api";

type Props = {
  setUser: (user: any) => void;
};

export default function SignInContainer({ setUser }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [state, setState] = useMergeState({
    isLoading: false,

    email: "",
    password: "",
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: "",
      },
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state?.email) {
      payload = { email: "Email/username is required", ...payload };
      isValid = false;
    }

    if (!state.password) {
      payload = { password: "Password is required", ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleSignIn = async () => {
    try {
      if (!isFormValid()) {
        return;
      }

      setState({ isLoading: true });

      const response = await signin({
        emailOrUsername: String(state?.email).trim(),
        password: String(state?.password).trim(),
      });

      localStorage.setItem(FOUR_ONE_ONE_TOKEN, response?.data?.token);

      setUser(response?.data?.user);

      navigate("/home");
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen h-full bg-[#EFF483] bg-opacity-38">
      <img src={Images.Logo} className="absolute top-8 left-8 h-12 lg:h-20" />

      <div className="py-32">
        <div className="text-green-dark text-2xl lg:text-5xl text-center">
          Friends help friends buy smarter.
        </div>

        <div className="text-green-lighter text-xl lg:text-2xl text-center mt-8">
          Know what’s good based on what your friends have already bought.
        </div>

        <div className="flex justify-center mt-8">
          <div className="w-11/12 lg:w-3/4">
            <TextField
              type="text"
              name="email"
              placeholder="Email used for receipts"
              value={state?.email}
              onChange={handleChange}
              error={state?.errors?.email}
            />

            <div className="mt-4">
              <TextField
                type="password"
                name="password"
                placeholder="Password"
                value={state?.password}
                onChange={handleChange}
                error={state?.errors?.password}
              />
            </div>

            <Button
              className="text-lg lg:text-2xl font-semibold px-6 w-full h-12 lg:h-16 mt-8"
              onClick={handleSignIn}
            >
              LOG IN
            </Button>

            <div className="text-gray-darker text-lg lg:text-2xl text-center mt-8">
              <Link to="/signup">SIGN UP</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
