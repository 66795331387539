import React from "react";
import { useSnackbar } from "notistack";
import Loader from "components/Loader";
import { useMergeState } from "utils/custom-hooks";
import ProductCard from "components/ProductCard";
import UserLink from "components/UserLink";
import { UserType } from "utils/types";
import { listMyList, removeFromMyList } from "api";

type Props = {
  user: UserType;
};

export default function MyListContainer({ user }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useMergeState({
    isLoading: false,
    mylist: [],
  });

  const init = async () => {
    try {
      setState({ isLoading: true });

      const response = await listMyList();

      setState({
        mylist: response?.data?.mylist,
      });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  const handleRemoveFromMyList = async (feedId: string) => {
    try {
      setState({ isLoading: true });

      const response = await removeFromMyList({ feed: feedId });

      enqueueSnackbar(response?.message, { variant: "success" });

      await init();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  return (
    <div>
      {state?.isLoading ? (
        <div className="w-full h-screen flex justify-center mt-10">
          <Loader loading={state?.isLoading} />
        </div>
      ) : (
        <div>
          <UserLink user={user} />

          <div className="font-bold text-xl mt-8">My List</div>

          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-8">
            {state?.mylist?.map((list: any) => (
              <ProductCard
                key={list?._id}
                feed={list?.feed}
                isMyList
                onRemoveFromMyList={handleRemoveFromMyList}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
