/* eslint-disable import/no-unresolved */
import React, { useCallback, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { Link, useSearchParams } from "react-router-dom";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import IconButton from "@mui/material/IconButton";
import { useMergeState } from "utils/custom-hooks";
import ProductCard from "components/ProductCard";
import Loader from "components/Loader";
import ShareProfileDialog from "components/ShareProfileDialog";
import { FeedType } from "utils/constants";
import { addToMyList, deleteFeed, inviteUser, listFeed, listMyList } from "api";
import { UserType } from "utils/types";
import { wrapFullName } from "utils/common";
import { debounce } from "lodash";

type Props = {
  user: UserType;
};

export default function FeedContainer({ user }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();
  const [pageIndex, setPageIndex] = useState(0);
  const loaderRef = useRef<HTMLDivElement>(null);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState("");

  const feedType = searchParams.get("type") || "";

  const [state, setState] = useMergeState({
    isLoading: false,
    mylist: [],
    feed: [],
    shouldShowShareProfileDialog: false,
  });

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // If the observed element is in view and we are not currently loading
        if (entries[0].isIntersecting && hasMore && !state?.isLoading) {
          setPageIndex((prevPageIndex) => prevPageIndex + 1);
        }
      },
      {
        // root: null, // using the viewport
        rootMargin: "5px",
        threshold: 0.1, // trigger the callback when 10% of the element is visible
      }
    );

    // Attach the observer to the loader div
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (loaderRef.current) {
        observer.disconnect();
      }
    };
  }, [hasMore, state.isLoading]);

  const handleOpenShareProfileDialog = () => {
    setState({ shouldShowShareProfileDialog: true });
  };

  const handleCloseShareProfileDialog = () => {
    setState({ shouldShowShareProfileDialog: false });
  };

  const handleShareProfile = async ({ email }: { email: string }) => {
    handleCloseShareProfileDialog();

    try {
      const response = await inviteUser({ email });
      enqueueSnackbar(response?.message, { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const init = async () => {
    try {
      setState({ isLoading: true });

      const mylistResponse = await listMyList();

      const response = await listFeed({
        type: feedType,
        pageIndex,
        search: searchQuery,
      });

      setState({
        mylist: mylistResponse?.data?.mylist,
        feed: response?.data?.feed,
      });
      if (response?.data?.feed.length !== 0) {
        setHasMore(true);
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };
  const handleAddToMyList = async (feedId: string) => {
    try {
      const response = await addToMyList({ feed: feedId });

      enqueueSnackbar(response?.message, { variant: "success" });

      await init();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleDeleteFeed = async (feedId: string) => {
    try {
      const response = await deleteFeed({ id: feedId });

      enqueueSnackbar(response?.message, { variant: "success" });

      await init();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  const loadData = useCallback(
    debounce(async () => {
      try {
        const response = await listFeed({
          type: feedType,
          pageIndex,
          search: searchQuery,
        });
        setState({
          feed:
            pageIndex === 0
              ? response.data.feed
              : [...state.feed, ...response.data.feed],
          isLoading: false,
        });
        setHasMore(response.data.feed.length > 0);
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    }, 200),
    [pageIndex, searchQuery, enqueueSnackbar]
  ); // Include all used variables in the dependency array

  React.useEffect(() => {
    loadData();
  }, [loadData]); // Re-fetch when page index changes

  const handleSearchChange = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setPageIndex(0); // Reset pageIndex to fetch from the beginning
      setHasMore(true);
      setState({ isLoading: true });
    }, 300),
    []
  );

  return (
    <div>
      <input
        type="text"
        name="search"
        placeholder="Search"
        autoComplete="off"
        value={searchData}
        onChange={(event) => {
          setSearchData(event.target.value);
          handleSearchChange(event.target.value);
        }}
        className="shadow rounded py-2 px-3 w-2/5 text-gray-700 leading-tight bg-[#F6F6F6] focus:outline-none focus:shadow-outline my-4"
      />
      {state?.isLoading ? (
        <div className="w-full h-screen flex justify-center mt-10">
          <Loader loading={state?.isLoading} />
        </div>
      ) : (
        <div>
          {feedType === FeedType.MyBuys && (
            <div className="flex flex-col lg:flex-row justify-between lg:items-center mb-8">
              <div className="flex items-center">
                <div className="text-xl mr-8">
                  {wrapFullName(user?.firstName, user?.lastName)}
                </div>

                <IconButton onClick={handleOpenShareProfileDialog}>
                  <IosShareOutlinedIcon sx={{ height: 18, width: 18 }} />
                </IconButton>
              </div>

              <div className="text-gray-dark text-lg flex items-center mt-4 lg:mt-0">
                <div className="mr-8">
                  <Link to="/friends" className="hover:underline">
                    <span className="font-bold mr-2">{user?.followers}</span>
                    <span>
                      {user?.followers > 1 ? "Followers" : "Follower"}
                    </span>
                  </Link>
                </div>
                <div>
                  <Link to="/friends" className="hover:underline">
                    <span className="font-bold mr-2">{user?.following}</span>
                    <span>Following</span>
                  </Link>
                </div>
              </div>
            </div>
          )}

          <div className="font-bold text-xl">
            {feedType === FeedType.Friends ? "Friend feed" : "My buys"}
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-8">
            {state?.feed?.map((feed: any) => (
              <ProductCard
                key={feed?._id}
                feed={feed}
                onAddToMyList={handleAddToMyList}
                mylist={state?.mylist}
                allowReview={feedType === FeedType.MyBuys}
                onDeleteFeed={handleDeleteFeed}
              />
            ))}
          </div>
        </div>
      )}

      {state?.shouldShowShareProfileDialog && (
        <ShareProfileDialog
          open={state?.shouldShowShareProfileDialog}
          onSubmit={handleShareProfile}
          onClose={handleCloseShareProfileDialog}
        />
      )}
      <div className="text-center" ref={loaderRef}>
        {hasMore ? (
          <Loader loading={hasMore} />
        ) : (
          "You've seen it all! Check back soon for new items"
        )}
      </div>
    </div>
  );
}
