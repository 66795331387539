import { Outlet } from "react-router-dom";
import Nav from "components/Nav";
import { UserType } from "utils/types";

const shouldRenderNavBar = () => {
  if (
    window.location.pathname.includes("/signup") ||
    window.location.pathname.includes("/signin")
  ) {
    return false;
  }

  return true;
};

type Props = {
  isLoggedIn: boolean;
  user: UserType;
  notificationStats: any;
};

export default function DefaultLayout({
  isLoggedIn,
  user,
  notificationStats,
}: Props) {
  return (
    <div>
      <div className="w-full min-h-screen sm:h-screen overflow-y-scroll overflow-x-scroll p-4">
        {isLoggedIn && shouldRenderNavBar() && (
          <Nav
            isLoggedIn={isLoggedIn}
            user={user}
            notificationStats={notificationStats}
          />
        )}

        <div className="my-4">
          <Outlet />
        </div>
      </div>

      <div className="fixed bottom-0 bg-white w-full italic lg:p-3 text-sm lg:text-base text-center">
        Send receipts to 4oneonezapier.dmg14s@zapiermail.com
      </div>
    </div>
  );
}
