import React from "react";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import UserLink from "components/UserLink";
import { useMergeState } from "utils/custom-hooks";
import { UserType } from "utils/types";
import { decideFollowRequest, listFollowRequests } from "api";
import Button from "components/Button";
import { FollowRequestDecision } from "utils/constants";

type Props = {
  user: UserType;
};

export default function FollowRequestsContainer({ user }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useMergeState({
    isLoading: false,
    followRequests: [],
  });

  const init = async () => {
    try {
      setState({ isLoading: true });

      const response = await listFollowRequests();

      setState({
        followRequests: response?.data,
      });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  const handleDecideFollowRequest = async (
    followRequestId: string,
    decision: string
  ) => {
    try {
      setState({ isLoading: true });

      const response = await decideFollowRequest({
        id: followRequestId,
        decision,
      });

      await init();
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  React.useEffect(() => {
    init();
  }, []);
  return (
    <div>
      {state?.isLoading ? (
        <div className="w-full h-screen flex justify-center mt-10">
          <Loader loading={state?.isLoading} />
        </div>
      ) : (
        <div>
          <UserLink user={user} />

          <div className="font-bold text-xl mt-8">Follow requests</div>

          <div className="w-full mt-8">
            {state?.followRequests?.map((request: any) => (
              <div key={request?._id}>
                <div className="lg:flex items-center my-8">
                  <div>
                    <span className="font-bold">
                      <Link
                        to={`/${request?.follower?.userName}`}
                        className="hover:underline"
                      >
                        {request?.follower?.firstName}
                      </Link>
                    </span>{" "}
                    <span className="ml-2">is requesting to follow you</span>
                  </div>

                  <div className="flex items-center mt-4 lg:ml-8 lg:mt-0">
                    <button
                      className="font-medium px-6 w-fit h-10 rounded-md bg-[#D0D3D9E5] text-[#50555CBF] border-[1px] border-[#19402714]"
                      onClick={() =>
                        handleDecideFollowRequest(
                          request?._id,
                          FollowRequestDecision.deny
                        )
                      }
                    >
                      Deny
                    </button>

                    <Button
                      className="font-medium px-6 w-fit h-10 rounded-md ml-10"
                      onClick={() =>
                        handleDecideFollowRequest(
                          request?._id,
                          FollowRequestDecision.accept
                        )
                      }
                    >
                      Accept
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
