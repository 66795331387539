import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import useGoogleAnalytics from "containers/analytics/useGaAnalytics";
import useGaConsent from "containers/analytics/useGaConsent";
import ConsentDialog from "containers/analytics/consentDialog";

// MUI theme
import theme from "mui";

// containers
import Routes from "routes";

export default function App() {
  const { hasConsent, openConsentDialog, handleClose } = useGaConsent();
  useGoogleAnalytics(hasConsent);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        hideIconVariant
        preventDuplicate
      >
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </SnackbarProvider>
      <ConsentDialog open={openConsentDialog} onClose={handleClose} />
    </ThemeProvider>
  );
}
