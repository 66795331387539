import React from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Images from "assets/images";
import { IconButton, Menu } from "@mui/material";
import { useMergeState } from "utils/custom-hooks";
import { UserType } from "utils/types";

type Props = {
  isLoggedIn: boolean;
  user: UserType;
  notificationStats: any;
};

const Navs = [
  {
    id: "1",
    label: "My buys",
    path: "/feed?type=mybuys",
    icon: Person2OutlinedIcon,
  },
  {
    id: "2",
    label: "My people",
    path: "/friends",
    icon: PeopleOutlinedIcon,
  },
  // {
  //   id: "3",
  //   label: "Follow requests",
  //   path: "/follow-requests",
  //   icon: PersonAddAltOutlinedIcon,
  // },
  {
    id: "4",
    label: "My saved",
    path: "/my-list",
    icon: AddOutlinedIcon,
  },
  {
    id: "5",
    label: "Settings",
    path: "/settings",
    icon: SettingsOutlinedIcon,
  },
  {
    id: "6",
    label: "Logout",
    path: "/logout",
    icon: LogoutOutlinedIcon,
  },
];

export default function Nav({ isLoggedIn, user, notificationStats }: Props) {
  const navigate = useNavigate();

  const [state, setState] = useMergeState({
    anchorEl: null,
  });

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setState({ anchorEl: event?.currentTarget });
  };

  const handleCloseMenu = () => {
    setState({ anchorEl: null });
  };

  const handleNavSelect = (nav: any) => {
    handleCloseMenu();
    navigate(nav?.path);
  };

  const handleRedirectNotifications = () => {
    navigate("/notifications");
  };

  return (
    <div className="w-full">
      <div className="flex justify-between items-center">
        <div>
          <Link to="/home" className="hover:underline">
            <img src={Images.Logo} className="h-12 lg:h-20" />
          </Link>
        </div>

        <div className="flex items-center">
          {/* <div className="text-dark text-xl font-medium mr-2 lg:mr-8">
            <Link to="/home" className="hover:underline">
              Home
            </Link>
          </div> */}

          <div className="lg:mr-2">
            <div className="relative">
              <IconButton onClick={handleRedirectNotifications}>
                <NotificationsOutlinedIcon />
              </IconButton>
              {notificationStats?.unreadCount > 0 && (
                <div className="bg-[#E1B9FE] w-2 h-2 rounded-full absolute top-3 right-3" />
              )}
            </div>
          </div>

          <IconButton onClick={handleOpenMenu}>
            <MenuOutlinedIcon />
          </IconButton>
        </div>
      </div>

      <Menu
        id="nav-menu"
        anchorEl={state?.anchorEl}
        open={Boolean(state?.anchorEl)}
        onClose={handleCloseMenu}
        sx={{ width: 320, maxWidth: "100%" }}
      >
        <MenuList>
          {Navs.map((nav) => (
            <MenuItem key={nav?.id} onClick={() => handleNavSelect(nav)}>
              <ListItemIcon>
                <nav.icon />
              </ListItemIcon>
              <span className="text-gray">{nav?.label}</span>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </div>
  );
}
