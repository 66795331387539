import { Link } from "react-router-dom";
import { wrapFullName } from "utils/common";
import { UserType } from "utils/types";

type Props = {
  user: UserType;
};

export default function UserLink({ user }: Props) {
  return (
    <div className="text-lg">
      <Link to="/feed?type=mybuys" className="hover:underline">
        {wrapFullName(user?.firstName, user?.lastName)}
      </Link>
    </div>
  );
}
