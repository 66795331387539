import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import TextField from "components/TextField";
import Button from "components/Button";
import { FOUR_ONE_ONE_TOKEN } from "utils/constants";
import { useMergeState } from "utils/custom-hooks";
import Images from "assets/images";
import { validateEmail } from "utils/common";
import { signup } from "api";

type Props = {
  setUser: (user: any) => void;
};

export default function SignUpContainer({ setUser }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [state, setState] = useMergeState({
    isLoading: false,

    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: "",
      },
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.userName) {
      payload = { userName: "User name is required", ...payload };
      isValid = false;
    }

    if (!state.firstName) {
      payload = { firstName: "First name is required", ...payload };
      isValid = false;
    }

    if (!state.lastName) {
      payload = { lastName: "Last name is required", ...payload };
      isValid = false;
    }

    if (!validateEmail(state.email)) {
      payload = { email: "Invalid email", ...payload };
      isValid = false;
    }

    if (!state.password) {
      payload = { password: "Password is required", ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleSignUp = async () => {
    try {
      if (!isFormValid()) {
        return;
      }

      setState({ isLoading: true });

      const response = await signup({
        userName: String(state?.userName).trim(),
        firstName: String(state?.firstName).trim(),
        lastName: String(state?.lastName).trim(),
        email: String(state?.email).trim(),
        password: String(state?.password).trim(),
      });

      localStorage.setItem(FOUR_ONE_ONE_TOKEN, response?.data?.token);

      setUser(response?.data?.user);

      navigate("/onboarding");
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen h-full bg-[#EFF483] bg-opacity-38">
      <img src={Images.Logo} className="absolute top-8 left-8 h-12 lg:h-20" />

      <div className="w-11/12 lg:w-3/5 py-20 lg:py-32">
        <div className="text-green-dark text-2xl lg:text-4xl text-center mt-8 lg:mt-0">
          Sign up with email
        </div>

        <div className="flex justify-center mt-8">
          <div className="w-11/12 lg:w-3/4">
            <TextField
              type="text"
              name="userName"
              placeholder="User name"
              value={state?.userName}
              onChange={handleChange}
              error={state?.errors?.userName}
            />

            <div className="mt-4">
              <TextField
                type="text"
                name="firstName"
                placeholder="First name"
                value={state?.firstName}
                onChange={handleChange}
                error={state?.errors?.firstName}
              />
            </div>

            <div className="mt-4">
              <TextField
                type="text"
                name="lastName"
                placeholder="Last name"
                value={state?.lastName}
                onChange={handleChange}
                error={state?.errors?.lastName}
              />
            </div>

            <div className="mt-4">
              <TextField
                type="text"
                name="email"
                placeholder="Email used for receipts"
                value={state?.email}
                onChange={handleChange}
                error={state?.errors?.email}
              />
            </div>

            <div className="mt-4">
              <TextField
                type="password"
                name="password"
                placeholder="Password"
                value={state?.password}
                onChange={handleChange}
                error={state?.errors?.password}
              />
            </div>

            <Button
              className="text-lg lg:text-2xl font-semibold px-6 w-full lg-12 lg:h-16 mt-8"
              onClick={handleSignUp}
            >
              SIGN UP
            </Button>

            <div className="text-gray-darker text-lg lg:text-2xl text-center mt-8">
              <Link to="/signin">SIGN IN</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
