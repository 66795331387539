import React from "react";
import ReactGA from "react-ga4";

const useGoogleAnalytics = (hasConsent: boolean | null) => {
  React.useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || "");
    ReactGA.send({ hitType: "pageview", page: document.location.pathname });
  }, [hasConsent]);
};

export default useGoogleAnalytics;
