/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-unresolved */
import React from "react";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import debounce from "lodash.debounce";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CustomRating from "components/Rating";
import { renderPrice, wrapFullName } from "utils/common";
import { useMergeState } from "utils/custom-hooks";
import { updateRating, updateReview } from "api";
import { IconButton } from "@mui/material";

const isAlreadyInMyList = (currentFeedId: string, mylist: Array<any>) =>
  mylist.find((elem: any) => elem?.feed?._id === currentFeedId);

type Props = {
  feed: any;
  mylist?: Array<any>;
  isMyList?: boolean;
  isPublicProfile?: boolean;
  shouldBlur?: boolean;
  allowReview?: boolean;
  onAddToMyList?: (feedId: string) => void;
  onRemoveFromMyList?: (feedId: string) => void;
  onDeleteFeed?: (feedId: string) => void;
};

const MyListButton = ({
  onClick,
  type,
}: {
  onClick: () => void;
  type: "add" | "remove";
}) => (
  <button
    className="font-medium px-3 h-8 rounded-md bg-[#BCB2DB] text-white text-sm bottom-0.5 absolute"
    onClick={onClick}
  >
    {type === "add" ? "Save" : "Unsave"}
  </button>
);

export default function ProductCard({
  feed,
  mylist = [],
  isMyList = false,
  isPublicProfile = false,
  shouldBlur = false,
  allowReview = false,
  onAddToMyList,
  onRemoveFromMyList,
  onDeleteFeed,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { product, user, commentCount = 0 } = feed;

  const [state, setState] = useMergeState({
    rating: 0,
    review: "",
  });

  const debouncedSaveRating = React.useCallback(
    debounce(async (rating: number | null) => {
      try {
        await updateRating({
          id: product?._id,
          rating,
        });
      } catch (error: any) {
        enqueueSnackbar(error?.message, { variant: "error" });
      }
    }, 1000),
    []
  );

  const debouncedSaveReview = React.useCallback(
    debounce(async (review: string) => {
      try {
        await updateReview({
          id: product?._id,
          review,
        });
      } catch (error: any) {
        enqueueSnackbar(error?.message, { variant: "error" });
      }
    }, 1000),
    []
  );

  const handleChangeRating = (newValue: number | null) => {
    setState({ rating: newValue });
    debouncedSaveRating(newValue);
  };

  const handleChangeReview = (event: any) => {
    setState({ review: event?.target?.value });
    debouncedSaveReview(event?.target?.value);
  };

  const handleAddToMyList = (productId: string) => {
    if (onAddToMyList) {
      onAddToMyList(productId);
    }
  };

  const handleRemoveFromMyList = (productId: string) => {
    if (onRemoveFromMyList) {
      onRemoveFromMyList(productId);
    }
  };

  const handleDelete = () => {
    if (onDeleteFeed) {
      onDeleteFeed(feed?._id);
    }
  };

  const shouldShowRating = () => {
    if (allowReview) {
      return true;
    }

    if (!allowReview && state?.rating) {
      return true;
    }

    return false;
  };

  const shouldShowReview = () => {
    if (allowReview) {
      return true;
    }

    if (!allowReview && state?.review) {
      return true;
    }

    return false;
  };

  React.useEffect(() => {
    setState({
      rating: product?.rating,
      review: product?.review,
    });
  }, []);

  return (
    <div className="bg-[#f6f6f6] rounded-md p-4 md:h-400 lg:h-400 m-1">
      {!isMyList && user?._id && !isPublicProfile && (
        <div className="p-1 text-center">
          <Link
            to={`/${user?.userName}`}
            className="font-semibold text-lg hover:underline"
          >
            {wrapFullName(user?.firstName, user?.lastName)}
          </Link>
        </div>
      )}

      <div className="px-2 shadow h-80 justify-center flex flex-col bg-[#ffffff] border rounded-md">
        <Link to={`/product?id=${product?._id}&feed=${feed?._id}`}>
          <div className="flex flex-col justify-center items-center ">
            <div
              className={`mt-1 text-center ${
                shouldBlur ? "blur-[2px]" : ""
              } line-clamp-1`}
            >
              {product?.productName}
            </div>

            <div className={`mt-1 text-center ${shouldBlur ? "blur" : ""}`}>
              {renderPrice(product?.price)}
            </div>

            <div className="mt-4 mb-2">
              <img
                src={product?.imgUrl}
                className={`${shouldBlur ? "blur-[2px]" : ""}  h-48 `}
              />
            </div>
          </div>
        </Link>

        <div className="w-full flex justify-end pt-4 px-4 relative">
          {!isMyList ? (
            isAlreadyInMyList(feed?._id, mylist) ? (
              <div className="font-medium px-2 w-fit h-8 absolute bottom-0 rounded-md bg-green-dark text-white text-sm flex justify-center items-center">
                <button onClick={() => handleRemoveFromMyList(feed?._id)}>
                  <CheckOutlinedIcon />
                </button>
              </div>
            ) : (
              <MyListButton
                onClick={() => handleAddToMyList(feed?._id)}
                type="add"
              />
            )
          ) : (
            <MyListButton
              onClick={() => handleRemoveFromMyList(feed?._id)}
              type="remove"
            />
          )}
        </div>
      </div>

      <div className="">
        <Link to={`/product?id=${product?._id}`}>
          {commentCount > 0 ? (
            <span>
              View {commentCount === 1 ? "" : "all "}
              {commentCount} {commentCount === 1 ? "comment" : "comments"}
            </span>
          ) : (
            <span>Comment</span>
          )}
        </Link>
      </div>

      {shouldShowRating() && (
        <div
          className={`flex justify-center mt-4 ${
            shouldBlur ? "blur-[2px]" : ""
          }`}
        >
          <CustomRating
            name="rating"
            value={state?.rating}
            size="large"
            onChange={(event, newValue) => {
              handleChangeRating(newValue);
            }}
            disabled={!allowReview}
            icon={<FavoriteOutlinedIcon fontSize="inherit" />}
            emptyIcon={<FavoriteBorderOutlinedIcon fontSize="inherit" />}
          />
        </div>
      )}

      {shouldShowReview() && (
        <div className="mt-4">
          {allowReview ? (
            <textarea
              name="review"
              rows={2}
              value={state?.review}
              onChange={handleChangeReview}
              placeholder="Leave a quick review"
              className="border-[1px] border-[#D7DBE1] rounded-md px-4 py-2 w-full placeholder:italic placeholder:text-[#00000040] resize-none"
            />
          ) : (
            <div
              className={`italic text-center ${
                shouldBlur ? "blur-[2px]" : ""
              } line-clamp-1`}
            >
              {state?.review}
            </div>
          )}
        </div>
      )}

      {onDeleteFeed && (
        <div className="flex justify-end">
          <IconButton onClick={handleDelete}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}
