// useGaConsent.tsx
import { useState, useEffect } from "react";

const useGaConsent = () => {
  const [hasConsent, setHasConsent] = useState<boolean | null>(null);
  const [openConsentDialog, setOpenConsentDialog] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("gaConsent");
    if (consent !== null) {
      setHasConsent(consent === "true");
    } else {
      setOpenConsentDialog(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem("gaConsent", String(true));
    setHasConsent(true);
    setOpenConsentDialog(false);
  };

  return { hasConsent, openConsentDialog, handleClose };
};

export default useGaConsent;
