export const FOUR_ONE_ONE_TOKEN = "FOUR_ONE_ONE_TOKEN";

export const FeedType = {
  Friends: "friends",
  MyBuys: "mybuys",
};

export const NotificationEventFeature = {
  comments: "comments",
  friends: "friends",
  followRequest: "follow_request",
  followRequestAccepted: "follow_request_accepted",
};

export const FollowRequestDecision = {
  accept: "accept",
  deny: "deny",
};

export const GenderOptions = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Non-binary",
    value: "non-binary",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const PreferencesOptions = [
  {
    label: "Beauty",
    value: "beauty",
  },
  {
    label: "Books",
    value: "books",
  },
  {
    label: "Hiking",
    value: "hiking",
  },
  {
    label: "Wellness",
    value: "wellness",
  },
  {
    label: "Travel",
    value: "travel",
  },
  {
    label: "Cooking",
    value: "cooking",
  },
  {
    label: "Running",
    value: "running",
  },
  {
    label: "Sneakers",
    value: "sneakers",
  },
  {
    label: "Music",
    value: "music",
  },
  {
    label: "Skiing",
    value: "skiing",
  },
  {
    label: "Food",
    value: "food",
  },
];
