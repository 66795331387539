import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";

const CustomRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#E1B9FE",
  },
  "& .MuiRating-iconHover": {
    color: "#E1B9FE",
  },
  "&.Mui-disabled": {
    opacity: 1,
  },
});

export default CustomRating;
