import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Dialog, DialogTitle } from "components/Dialog";
import { useMergeState } from "utils/custom-hooks";
import { validateEmail } from "utils/common";
import ErrorMessage from "components/ErrorMessage";

type Props = {
  open: boolean;
  onSubmit: (payload: { email: string }) => void;
  onClose: () => void;
};

export default function ShareProfileDialog({ open, onSubmit, onClose }: Props) {
  const [state, setState] = useMergeState({ email: "" });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!validateEmail(state?.email)) {
      payload = { ...payload, email: "Please enter a valid email" };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleSubmit = () => {
    if (!isFormValid()) {
      return;
    }

    onSubmit({ email: state?.email });
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>
        Share your profile with friends
      </DialogTitle>

      <DialogContent dividers>
        <div className="my-4">
          <input
            type="text"
            name="email"
            placeholder="Add their email to send an invite"
            value={state?.email}
            onChange={handleChange}
            className={`border rounded-md font-medium h-12 px-4 w-full placeholder:text-slate placeholder:font-medium ${
              state?.errors?.email ? "border-red-500" : "border-slate"
            }`}
          />
          {state?.errors?.email && (
            <ErrorMessage message={state?.errors?.email} />
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <button
          className="font-semibold px-6 w-fit h-10 rounded-md bg-green-dark text-white"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </DialogActions>
    </Dialog>
  );
}
